/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ChangeEvent } from "react";
import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import "./Login.css";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import { withRouter } from "./withRouter";

// import Constants from "./util/Constants";
import AUTHAPI from "./http/auth";

// stored value
// const instituteBoardId = localStorage.getItem("co.vidya.institute_board_id");
// const insttuteName = localStorage.getItem("co.vidya.insttute_name");
// const email = localStorage.getItem("co.vidya.email");
// const password = localStorage.getItem("co.vidya.password");


class Login extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "avp@classess.com",
      password: "classess123",
      showPassword: false,
      snackbarOpen: false,
      snackbarMessage: "",
      isSubmitting: false
    };
  }
  handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;

    // Check if the input exceeds 50 characters
    if (inputText.length <= 50) {
      this.setState({ email: inputText });
    }
  };

  handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;

    // Check if the input exceeds 15 characters
    if (inputText.length <= 30) {
      this.setState({ password: inputText });
    }
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false, snackbarMessage: "" });
  };

  passwordVisible = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  validate = () => {
    const errors = {
      email: "",
      password: "",
    };
    const checkmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // let checknumber = /^\d{10}$/;
    if (!this.state.email) {
      errors.email = "Email is required!";
    } else if (!checkmail.test(this.state.email)) {
      errors.email = "This is not a valid email format!";
    } else if (this.state.email.length > 50) {
      errors.email = "Email cannot exceed 50 characters!";
    }

    if (!this.state.password) {
      errors.password = "Password is required!";
    }
    return errors;
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // prevent the default form submission behavior
    const responseError = this.validate();
    this.setState({ isSubmitting: true });

    if (responseError.email === "" && responseError.password === "") {
      try {
        const formData = {
          email: this.state.email,
          password: this.state.password,
        };

        const resp1 = await AUTHAPI.doLogin(formData);
        console.log("handleSubmit response", resp1);

        if (
          //   response.status === 200 &&
          //   response.data.message === "Login successful"
          resp1.message === "pass"
          && resp1.data
          && resp1.data.login_status
          && resp1.data.login_status.login_status
          && (resp1.data.login_status.login_status === 'auth-success')
        ) {
          // Handle successful login
          const { message, data } = resp1;
          const { institute } = data;
          const {
            institute_id,
            institute_board_id,
            academic_year,
            institute_academic_year_id,
            institute_academic_year,
            institute_logo,
            institute_name,

          } = institute;

          let instLogo = institute_logo;
          if(!institute_logo)
            instLogo = `https://classess.com/img/Classess.png`;

          // const { institute_id } = institute_academic_year;

          // Set localStorage items

          localStorage.setItem("board.classess.userisloggedin", "yes");

          localStorage.setItem("board.classess.institute_id", institute_id);
          localStorage.setItem(
            "board.classess.institute_board_id",
            institute_board_id,
          );

          localStorage.setItem("board.classess.institute_name", institute_name);

          localStorage.setItem(
            "board.classess.institute_email",
            this.state.email,
          );

          localStorage.setItem("board.classess.institute_logo", instLogo);
          localStorage.setItem("board.classess.academic_year", academic_year);

          localStorage.setItem(
            "board.classess.institute_academic_year_id",
            institute_academic_year_id,
          );
          localStorage.setItem("board.classess.institute_academic_year", institute_academic_year);


          this.setState({
            snackbarOpen: true,
            snackbarMessage: "SignIn Successful",
          });

          
          // Redirect to dashboard
          console.log("Redirecting to dashboard...");
          this.props.router.navigate("/dashboard");
        } else {
          // Handle unsuccessful login
          this.setState({
            snackbarOpen: true,
            snackbarMessage:
              "SignIn failed: User not found or Incorrect credentials",
          });
        }

        this.setState({ isSubmitting: false });
      } catch (error) {
        console.error("Login error:", error);
        this.setState({
          snackbarOpen: true,
          snackbarMessage:
            "Invalid credentials, Check your Email and Password again",
        });

      }

    } else {
      // Handle validation errors
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Please enter valid email and password",
      });
    }
    
    this.setState({ isSubmitting: false });
  };

  render() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f4f6f8",
        }}
      >
        <Grid2 container spacing={2} 
        sx={{
          width: '30%'
        }}
        >
          <Grid2 
          sx={{
            width: '100%'
          }}>
            <div
            style={{
              alignSelf: 'center',
              textAlign: 'center',
              width: '100%',
              marginBottom: 10
            }}>
            <img src="https://classess.com/schooler/examer/assets/Classesslogo.png" height={50} />
            </div>
            <Card
              sx={{
                p: 5,
                boxShadow: 3,
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h5"
                align="center"
                sx={{ fontWeight: "bold", color: "#333", mb: 4 }}
              >
                Sign In To Classboard
              </Typography>
              <form onSubmit={this.handleSubmit}>
                <Typography variant="body1" sx={{ mb: 1, color: "#666" }}>
                  Email
                </Typography>
                <TextField
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 2 }}
                  required
                />
                <Box sx={{ color: "red", mb: 2 }}>
                  {this.state.email === "" ? "" : this.validate().email}
                </Box>

                <Typography variant="body1" sx={{ mb: 1, color: "#666" }}>
                  Password
                </Typography>
                <TextField
                  type={this.state.showPassword ? "text" : "password"}
                  name="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={this.passwordVisible}>
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                  required
                />
                <Box sx={{ color: "red", mb: 2 }}>
                  {this.state.password === "" ? "" : this.validate().password}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#1976d2",
                      color: "#fff",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#1565c0",
                      },
                      py: 1.5,
                    }}
                    disabled={this.state.isSubmitting}
                  >
                    Sign In
                  </Button>
                </Box>

                <Snackbar
                  open={this.state.snackbarOpen}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  autoHideDuration={4000}
                  onClose={this.handleSnackbarClose}
                  message={this.state.snackbarMessage}
                />
              </form>
            </Card>
          </Grid2>
        </Grid2>
      </Box>
    );
  }
}

export default withRouter(Login);
