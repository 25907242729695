/* eslint-disable prettier/prettier */
// Constants for global use

// /institute/get-classroom-upcoming-lessons/4158
const Constants = {
    // Add your constants here
    // API_ENDPOINT: 'http://192.168.29.224/nodeserver',
    API_ENDPOINT: 'https://api.kgtopg.com',
    // PLATFORM_ENDPOINT: 'http://192.168.29.224/platform',
    PLATFORM_ENDPOINT: 'https://admin.kgtopg.com',
    // SPRING_ENDPOINT: 'http://192.168.29.224:8080/examerspring',
    SPRING_ENDPOINT: 'https://api2.classess.com',
    // PARTNER_ENDPOINT: 'http://192.168.29.224/partner',
    PARTNER_ENDPOINT: 'https://partner.kgtopg.com',
    // SITE_URL: 'http://dev.classboard',
    SITE_URL: 'https://classroom.kgtopg.com',
    // INSTITUTE_ID: 291, // local
    INSTITUTE_ID: 1101,
    // YEAR_ID: 339, // local
    YEAR_ID: 1107,
    // OPENAI_ENDPOINT: 'http://192.168.29.224/examer',
    OPENAI_ENDPOINT: 'https://live.kgtopg.com',
    KEY_WHITEBOARD_PARAMS: "co.vidya.whiteboardParams"
}

export default Constants;
