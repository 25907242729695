import { useNavigate, useLocation, useParams } from "react-router-dom";
import React from "react";

export function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Component {...props} router={{ navigate, location, params }} />;
  }

  return ComponentWithRouterProp;
}
