/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Link } from "react-router-dom";
import { shield } from "../../packages/excalidraw/components/icons";
import { Tooltip } from "../../packages/excalidraw/components/Tooltip";
import { useI18n } from "../../packages/excalidraw/i18n";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";

import { DrawerContent } from "../src/Drawer1";
import SwipeRightIcon from "@mui/icons-material/SwipeRight";
import CollectionsIcon from "@mui/icons-material/Collections";
import  GalleryComponent  from "../src/Gallery";
import CloseIcon from "@mui/icons-material/Close";

// dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { Button } from "@excalidraw/excalidraw";
import React, { useState } from "react";
import Button from "@mui/material/Button";

import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

import LESSONAPI from "../src/http/lessonapi";
import IconButton from "@mui/material/IconButton";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
// import { Tooltip } from '@mui/material';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';

import { useNavigate } from 'react-router-dom';

// AI bot
import Vidya from "../src/frags/Vidya";

export const EncryptedIcon = (props: any) => {
  const { plan, state } = props;
  const { t } = useI18n();
  const [snackOpen, setSnackOpen] = useState(false);
  const navigate = useNavigate();

  function SimpleSnackbar() {
    const [open, setOpen] = React.useState(snackOpen);

    const handleClick = () => {
      setOpen(true);
    };

    const handleClose = (
      event: React.SyntheticEvent | Event,
      reason?: SnackbarCloseReason,
    ) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    };

    // unused
    const action = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    return (
      <div>
        {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Ending the Lesson. Please wait..."
          // action={action}
        />
      </div>
    );
  }

  function EndLessonButton() {
    const [open, setOpen] = React.useState(false);

    const onLessonEnded = () => {
      handleClickOpen();
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          
        </Button> */}
        <CloseIcon
          onClick={onLessonEnded}
          style={{ cursor: "pointer", color: "black" }}
        />

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"End Lesson?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Have you ended the lesson? Pressing OK will end lesson plan You
              won"t be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                callForceEndLesson(1);
              }}
            >
              Yes, End Lesson!
            </Button>
            <Button
              onClick={() => {
                handleClose();
                callForceEndLesson(0); // do not end topic
              }}
              autoFocus
            >
              No, only end the session!
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  const onLessonEnded = () => {
    //		alert('lesson ended called..')
    // callForceEndLesson()
    /*
    const recbtn = document.getElementById('startrec');
 
    if(isRecording) {
 
        // stop
//				$('#startrec').click();
        recbtn.click();
        
        Swal.fire({
          title: 'Your recording is processing, please wait for a few moments..',
          text: "Saving in progress!",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
           confirmButton: "btn btn-secondary"
          }
         }).then(function(result) {
            console.log('calling force end lesson..')
            callForceEndLesson();
        });
        
    } else {
        callForceEndLesson()
    }
    */
  };

  const VidyaButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        {/* <Button onClick={() => setIsOpen(true)}>Open Vidya</Button> */}
        <SmartToyOutlinedIcon 
          style={{ color: "rgb(10,10,10)", cursor: "pointer" }}
          onClick={() => setIsOpen(true)}
        />
        <Vidya open={isOpen} onClose={() => setIsOpen(false)} plan={plan} 
          state={state}
          />
      </>
    );
  };

  const callForceEndLesson = (endTopic: any) => {
    const {
      classroomId,
      staffId,
      subjectSubId,
      staffLsnId,
      chpId,
      tpcId,
      oPlanId,
    } = state;
    // end lesson
    const ended = LESSONAPI.onLessonEndedForce(endTopic, state); // ONLY WHEN HE PRESSES END TOPIC
    console.log("callForceEndLesson ended", ended);

    // onLessonEndedForce(0) // ALL OTHER SCENARIO IT IS ONLY PERIOD ENDING

    // showing snackbar
    setSnackOpen(true);
    setTimeout(() => {
      window.history.back();
    }, 1000); // wait a second
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "10px",
        padding: 5,
        background: 'whitemoke',
        borderRadius: 5,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
      }}
    >
      {/* <a
        className="encrypted-icon tooltip"
        href="https://blog.excalidraw.com/end-to-end-encryption/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={t("encrypted.link")}
        style={{ marginRight: "5px" }}
      >
        <Tooltip label={t("encrypted.tooltip")} long={true}>
          {shield}
        </Tooltip>
      </a> */}

      {/* here added open drawer sidebar */}
      <Tooltip label="Open Instructions">
      <DrawerContent plan={plan} />
      </Tooltip>

      {/* here added open chatbot open ai page */}
      <Tooltip label="Open Chat" >
      <VidyaButton />
      </Tooltip>

      {/* here added swiper slides page */}
      <Tooltip label="Open Presentations" >

      {/* <Link to="/swiper"> */}
        <SubscriptionsOutlinedIcon   style={{ color: "rgb(10,10,10)" }}  
        onClick={() => {
          
          navigate("/swiper", {
            state: state,
          });
        }}
        />
      {/* </Link> */}

      </Tooltip>

      {/* here added gallery attachments */}
      <Tooltip label="Gallery" >
      <GalleryComponent />
      </Tooltip>

      {/* end lesson icon */}
      <Tooltip label="End Class">
      <EndLessonButton />
      </Tooltip>

      {/* snackbar */}
      <SimpleSnackbar />
    </div>
  );
};
