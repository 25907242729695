import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Swiper.css";
import { withRouter } from './withRouter';

import { Pagination, Mousewheel } from "swiper/modules";
import Constants from "./util/Constants";

interface Slide {
  staff_lesson_slide_id: number;
  staff_lesson_slide_name: string;
  staff_lesson_slide_path: string;
}

interface WhiteboardWithSlidesState {
  slides: Slide[];
  isLoading: boolean;
  error: string | null;
}

interface WhiteboardWithSlidesProps {
  router: {
    navigate: (path: string, options?: any) => void;
    location: {
      state: {
        clrId: string;
        clsStd: string;
        instId: string;
        staffId: string;
        subId: string;
        subSubId: string;
        oPlanId: string;
        yearId: string;
      };
    };
    params: any;
  };
}

class WhiteboardWithSlides extends React.Component<WhiteboardWithSlidesProps, WhiteboardWithSlidesState> {
  constructor(props: WhiteboardWithSlidesProps) {
    super(props);
    this.state = {
      slides: [],
      isLoading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSlides();
  }

  fetchSlides = async () => {
    const { router } = this.props;
    const state = router.location.state;

    console.log('fetchSlides props', this.props);
    console.log('fetchSlides router', router);
    console.log('fetchSlides state', state);

    const { clsStd, subSubId, staffId, oPlanId, yearId } = state;

    try {
      const response = await fetch(
        `${Constants.PLATFORM_ENDPOINT}/staff-lesson-slide-list/?classroom_id=${clsStd}&subject_sub_id=${subSubId}&staff_id=${staffId}&lsn_id=${oPlanId}&academic_year_id=${yearId}&lpbpab_id=0&day=99`
      );
      const data = await response.json();
      this.setState({ slides: data, isLoading: false });
    } catch (error) {
      this.setState({ error: "Failed to fetch slides", isLoading: false });
    }
  };

  handleFileByExtension(fileName: string, filePath: string) {
    console.log("handleFileByExtension fileName: " + fileName);
    console.log("handleFileByExtension filePath", filePath);

    const filename = filePath.split("/").pop()?.split("?")[0] || "";
    const extension = filename.split(".").pop()?.toLowerCase();
    console.log("handleFileByExtension extension", extension);

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <div className="slide-content">
            <img
              src={filePath}
              alt={fileName}
              style={{ 
                height: 'auto', 
                maxHeight: '100vh', 
                objectFit: 'contain',
              }}
            />
          </div>
        );

      case "pdf":
      case "doc":
      case "docx":
        return (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(filePath)}&embedded=true`}
            title={fileName}
            style={{ 
              height: 'auto', maxHeight: '100vh', width: '100%' 
            }}
          />
        );

      default:
        if (filePath.includes("youtube.com") || filePath.includes("youtu.be")) {
          return (
            <iframe
              width="100%"
              height="100%"
              src={filePath}
              title={fileName}
              allowFullScreen
            />
          );
        }
        return null;
    }
  }

  renderSlideContent = (slide: Slide) => {
    const { staff_lesson_slide_path, staff_lesson_slide_name } = slide;
    return this.handleFileByExtension(staff_lesson_slide_name, staff_lesson_slide_path);
  };

  render() {
    const { slides, isLoading, error } = this.state;

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
      <div className="whiteboard-container">
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          modules={[Mousewheel, Pagination]}
          className="mySwiper"
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.staff_lesson_slide_id}>
              {this.renderSlideContent(slide)}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
}

export default withRouter(WhiteboardWithSlides);