// authentication and security APIs

import Constants from "../util/Constants";
export default {
    async doLogin(formData: any) {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(formData), // Convert the form data to a JSON string
          redirect: "follow",
        };

        // const response = await axios.post<any, any>(
        //   "http://192.168.29.224:8080/examerspring/user/do-login",
        //   formData,
        // );

        const response = await fetch(
          Constants.SPRING_ENDPOINT + "/user/do-login",
          requestOptions,
        );
        const resp1 = await response.json();

        if(resp1) {
            return resp1;
        } else {
            return false;
        }
    }
}
