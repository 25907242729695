import type { ExcalidrawElement } from "../../packages/excalidraw/element/types";
import type { AppState } from "../../packages/excalidraw/types";
import {
  clearAppStateForLocalStorage,
  getDefaultAppState,
} from "../../packages/excalidraw/appState";
import { clearElementsForLocalStorage } from "../../packages/excalidraw/element";
import { STORAGE_KEYS } from "../app_constants";

export const saveUsernameToLocalStorage = (username: string) => {
  try {
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_COLLAB,
      JSON.stringify({ username }),
    );
  } catch (error: any) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const importUsernameFromLocalStorage = (): string | null => {
  try {
    const data = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);
    if (data) {
      return JSON.parse(data).username;
    }
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};


// // New function to clear localStorage
// const clearLocalStorage = () => {
//   localStorage.removeItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
//   localStorage.removeItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
// };


export const importFromLocalStorage = (staffId: any, subId: any, subSubId: any) => {
  let savedElements = null;
  let savedState = null;

  // New localStorage keys for each sub-sub-id
  let savedElements1 = null;
  let savedState1 = null;

  const LOCAL_STORAGE_ELEMENTS1 = "co.vidya."+staffId+"."+subId+"."+subSubId+".elements";
  const LOCAL_STORAGE_APP_STATE1 = "co.vidya."+staffId+"."+subId+"."+subSubId+".state";

  console.log('importFromLocalStorage LOCAL_STORAGE_ELEMENTS1', LOCAL_STORAGE_ELEMENTS1);
  console.log('importFromLocalStorage LOCAL_STORAGE_APP_STATE1', LOCAL_STORAGE_APP_STATE1);
  try {
    savedElements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    savedState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);


    savedElements1 = localStorage.getItem(LOCAL_STORAGE_ELEMENTS1);
    savedState1 = localStorage.getItem(LOCAL_STORAGE_APP_STATE1);


    console.log('importFromLocalStorage savedElements: ', savedElements);
    console.log('importFromLocalStorage savedState: ', savedState);

    
    console.log('importFromLocalStorage savedElements1: ', savedElements1);
    console.log('importFromLocalStorage savedState1: ', savedState1);
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  let elements: ExcalidrawElement[] = [];
  if (savedElements1) {
    try {
      elements = clearElementsForLocalStorage(JSON.parse(savedElements1));
    } catch (error: any) {
      console.error(error);
      // Do nothing because elements array is already empty
    }
  }

  let appState = null;
  if (savedState) {
    try {
      appState = {
        ...getDefaultAppState(),
        ...clearAppStateForLocalStorage(
          JSON.parse(savedState) as Partial<AppState>,
        ),
      };
    } catch (error: any) {
      console.error(error);
      // Do nothing because appState is already null
    }
  }
  return { elements, appState };
};

export const getElementsStorageSize = () => {
  try {
    const elements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    const elementsSize = elements?.length || 0;
    return elementsSize;
  } catch (error: any) {
    console.error(error);
    return 0;
  }
};

export const getTotalStorageSize = () => {
  try {
    const appState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
    const collab = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);

    const appStateSize = appState?.length || 0;
    const collabSize = collab?.length || 0;

    return appStateSize + collabSize + getElementsStorageSize();
  } catch (error: any) {
    console.error(error);
    return 0;
  }
};
