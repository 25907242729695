/* eslint-disable curly */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Drawer, Box, IconButton, Typography, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { Link, useNavigate } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Chip,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";

export const DrawerContent = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { plan } = props;

  // Function to handle opening the drawer
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // Function to handle closing the drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  console.log('DrawerContent plan', plan);

  const parseJSON = (jsonString: string) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  const INSTRUCTIONS_PANEL = () => {
    const { names_dict, data: instructionBlocks } = plan;

    return (
      // sx={{ p: 3 }} removed padding 
      <Box >
      <Typography variant="h5" gutterBottom>
        Lesson Plan: {names_dict.chapter_name}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Day {names_dict.icc_day}
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Timeline
        sx={{
          [`& .MuiTimelineItem-root:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {instructionBlocks.map((block, index) => {
          const content = parseJSON(block.generated_text);
          if (!content) return null;

          return (
            <TimelineItem key={block.icc_id}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index < instructionBlocks.length - 1 && (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {block.lpbpab_name}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {content.text}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      label={`${content.time}`}
                      size="small"
                      color="primary"
                    />
                    {/* removed day 1text */}
                    {/* <Typography variant="caption" color="text.secondary">
                      {content.day}
                    </Typography> */}
                  </Box>
                  {content.topics && (
                    <List dense>
                      {content.topics.map((topic) => (
                        <ListItem key={topic["topic-id"]}>
                          <ListItemText primary={topic.topic_name} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
    );
  };

  // UNUSED
  const INSTRUCTIONS_PANEL_OLD = () => {
    return (
      <Box sx={{ width: 250, padding: 2 }} role="presentation">
        {/* Close button inside drawer */}
        <IconButton onClick={handleDrawerClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>

        {/* Instructional Content for Students */}
        <Typography
          variant="h5"
          sx={{ color: "#1976d2", marginBottom: 2, fontWeight: "bold" }}
        >
          Instructions for Students
        </Typography>

        <ul
          style={{
            lineHeight: "1.8em",
            fontSize: "1rem",
            paddingLeft: "1.2em",
            color: "#555", // Gray color for text
            fontFamily: "Arial, sans-serif",
          }}
        >
          <li>Follow the guidelines carefully before starting the test.</li>
          <li>Ensure that you have a stable internet connection.</li>
          <li>Complete the test within the given time frame.</li>
          <li>Do not close the browser window during the test.</li>
          <li>Contact your instructor if you face any issues.</li>
        </ul>

        <Typography
          sx={{
            marginTop: "1.5em",
            fontSize: "1rem",
            color: "#777", // Slightly lighter gray for closing message
            fontFamily: "Arial, sans-serif",
            alignItems: "center",
          }}
        >
          Good luck!
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      {/* Link to trigger the drawer */}
      <Link
        onClick={handleDrawerOpen}
        style={{
          fontSize: "1.2em",
          cursor: "pointer",
          justifyContent: "center",
          display: "block",
        }}
        to={""}
      >
        <DescriptionOutlinedIcon  style={{ color: "rgb(10,10,10)" }}  />
      </Link>

      {/* Drawer Component */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 400, padding: 2 }} role="presentation">
          <div
          style={{
            position: 'absolute',
            top: 10,
            right: 10
          }}>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {/* Drawer Content */}
          {plan ?
            <>
              <INSTRUCTIONS_PANEL />
            </>
            :
            <>
              {/* no plan */}
              <Alert variant="filled" severity="error">
                No lesson plan available. Please create a lesson plan first.
              </Alert>
            </>
          }
        </Box>
      </Drawer>
    </div>
  );
};
