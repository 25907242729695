/* eslint-disable no-else-return */
/* eslint-disable no-console */
// HTTP methods for API requests for Lessons

import Constants from "../util/Constants";

export default {
  async onLessonEndedForce(publish: any, params: any) {
    // close lesson timer

    // close all quiz

    console.log(`onLessonEndedForce ending the lesson... with publish status ${publish}`);
    console.log('onLessonEndedForce params', params);
    //		Swal.fire('Ending the current class..')
    /*
                Swal.fire({title: 'Ending the current class..',
                    showCancelButton: false,
                    showConfirmButton: false
                })
                    */

    const {
      clrId,
      staffId,
      subSubId,
      sLsnId,
      oPlanId,
    } = params;

    try {

      const URL = `${Constants.SITE_URL}/schooler/teacher-end-meeting`;
      const queryStr = `${publish}&classroomid=${clrId}&staffid=${staffId}&subid=${subSubId}&slessonid=${sLsnId}&liveid=0&chpid=${0}&tpid=${0}&planid=${oPlanId}&iframe=true&restreq=true`;
      const URLEX = `${URL}?publish=${queryStr}`;

      // call API
      const resp = await fetch(URLEX); // JUST GET REQUEST
      const resp2 = await resp.json();

      if (resp2) {
        return resp2;
      } else {
        return false;
      }

    } catch (error) {
      console.error('Error onLessonEndedForce exception:', error);
      return false;
    }

    /*

        // const data2 = 'publish='+publish+'&classroomid=${classroomId }&staffid=${staffId }&subid=${subjectSubId }&slessonid='+staffLessonId+'&liveid=0&chpid='+oChapId+'&tpid='+oTopicId+'&planid='+oPlanId+'&iframe=true';
        console.log('onLessonEndedForce() data2', data2);
        
        let dte = new Date();
        let dtStr = dte.toISOString().split('.')[0]+"Z";
        
        
        $.ajax({
            url: 'teacher-end-meeting',
            data: data2,
            async: true,
            method: 'GET',
            success: function(response) {
                //console.log('on ended ' + JSON.stringify(response))
                let stuLen = (response.students) ? parseInt(response.students) : 0;
 
                // close session ended modal in 10s and redirect to teacher dashboard
                setTimeout(function() {
//					$('#myModal').fadeOut()
                    window.location.assign('teacher-class2?id=${classroomId }&subid=${subjectSubId }&datetime='+dtStr);
//					window.location.reload();
                }, 2000);
                
            },
            error: function(err) {
                //console.log('error ' + JSON.stringify(err))
            }
        })
        
        */
  },
};
