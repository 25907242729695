/* eslint-disable no-console */
// API of AI model chosen from the frontend


import Constants from "../util/Constants";
export default {
  async generateContentAuto(prompt: string) {
    // Send the prompt to the AI model
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const formData = {
        generated_text: 'Use your database',
        prompt: prompt,
    };

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(formData), // Convert the form data to a JSON string
      redirect: "follow",
    };

    const resp = await fetch(
      `${Constants.OPENAI_ENDPOINT}/api/generate-content-auto/`,
      requestOptions,
    );

    const resp2 = await resp.json();

    if (resp2) {
      return resp2;
    }
    return false;
  },

  anotherFunc() {
    // More API requests...
  },
}

