import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Modal,
  Grid,
  Button,
} from "@mui/material";

import CollectionsIcon from "@mui/icons-material/Collections";
import CloseIcon from "@mui/icons-material/Close";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";

import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';

interface GalleryItem {
  staff_lesson_slide_id: number;
  staff_lesson_slide_name: string;
  staff_lesson_slide_type: string;
  staff_lesson_slide_path: string;
}

interface GroupedGalleryItems {
  [key: string]: GalleryItem[];
}

interface State {
  drawerOpen: boolean;
  modalOpen: boolean;
  selectedItem: GalleryItem | null;
  groupedItems: GroupedGalleryItems;
  isLoading: boolean;
  error: string | null;
  numPages: number | null;
}

class GalleryComponent extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      drawerOpen: false,
      modalOpen: false,
      selectedItem: null,
      groupedItems: {},
      isLoading: false,
      error: null,
      numPages: null,
    };
  }

  handleDrawerOpen = async () => {
    this.setState({ drawerOpen: true, isLoading: true });

    try {
      const response = await axios.get<GalleryItem[]>(
        "https://admin.kgtopg.com/staff-lesson-slide-list/?classroom_id=10&subject_sub_id=8&staff_id=776&lsn_id=5913&academic_year_id=1107&lpbpab_id=0&day=1",
      );
      const groupedItems = this.groupItemsByType(response.data);
      this.setState({ groupedItems, isLoading: false });
    } catch (error) {
      console.error("Error fetching gallery items:", error);
      this.setState({
        error: "Failed to load gallery items",
        isLoading: false,
      });
    }
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleModalOpen = (item: GalleryItem) => {
    this.setState({ modalOpen: true, selectedItem: item });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false, selectedItem: null });
  };

  groupItemsByType = (items: GalleryItem[]): GroupedGalleryItems => {
    return items.reduce((acc, item) => {
      if (!acc[item.staff_lesson_slide_type]) {
        acc[item.staff_lesson_slide_type] = [];
      }
      acc[item.staff_lesson_slide_type].push(item);
      return acc;
    }, {} as GroupedGalleryItems);
  };

  getYoutubeId = (url: string) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  renderThumbnail = (item: GalleryItem) => {

    switch (item.staff_lesson_slide_type) {
      case "video/streaming":
        const youtubeId = this.getYoutubeId(item.staff_lesson_slide_path);
        return (
          <img
            src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
            alt="YouTube Thumbnail"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
          />
        );

      case "application/pdf":
        return <PictureAsPdfIcon style={{ fontSize: 60, color: '#f44336' }} />;

      // return (
      //   <img
      //     src="/path-to-your-pdf-thumbnail.png" // Replace with your PDF icon or use an SVG
      //     alt="PDF Thumbnail"
      //     style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
      //   />
      // );

      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return (
          <img
            src={item.staff_lesson_slide_path}
            alt={item.staff_lesson_slide_name}
            style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
          />
        );

      default:
        return <p>Unsupported media type</p>;
    }

  };

  onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    this.setState({ numPages });
  };

  renderFullContent = (item: GalleryItem) => {
    switch (item.staff_lesson_slide_type) {
      case "video/streaming":
        const youtubeId = this.getYoutubeId(item.staff_lesson_slide_path);
        return (
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${youtubeId}`}
            title={item.staff_lesson_slide_name}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        );
      case "application/pdf":
        return (
          <object
            data={item.staff_lesson_slide_path}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>
              It appears you don't have a PDF plugin for this browser. You can{" "}
              <a href={item.staff_lesson_slide_path}>
                click here to download the PDF file.
              </a>
            </p>
          </object>
        );
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return (
          <img
            src={item.staff_lesson_slide_path}
            alt={item.staff_lesson_slide_name}
            style={{ maxWidth: "100%", height: "auto" }}
          />
        );
      default:
        return <p>Unsupported media type: {item.staff_lesson_slide_type}</p>;
    }
  };


  render() {
    const {
      drawerOpen,
      modalOpen,
      selectedItem,
      groupedItems,
      isLoading,
      error,
    } = this.state;

    return (
      <div>
        {/* <Link
          onClick={this.handleDrawerOpen}
          style={{
            fontSize: "1.2em",
            cursor: "pointer",
            justifyContent: "center",
            display: "block",
          }}
          to=""
        >
          <CollectionsOutlinedIcon   style={{ color: "rgb(10,10,10)" }} />
        </Link> */}

        <Box
          onClick={this.handleDrawerOpen}
          style={{
            fontSize: "1.2em",
            cursor: "pointer",
            justifyContent: "center",
            display: "block",
          }}
        >
          <CollectionsOutlinedIcon style={{ color: "rgb(10,10,10)" }} />
        </Box>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={this.handleDrawerClose}
        >
          <Box
            sx={{ width: 400, padding: 2 }} role="presentation">
            <IconButton
              onClick={this.handleDrawerClose}
              style={{ float: "right" }}
            >
              <CloseIcon />
            </IconButton>

            <Typography
              variant="h5"
              style={{
                color: "#1971c2",
                marginBottom: 5,
                marginTop: 5,
                alignItems: "center",
                fontWeight: "bold",
                justifyContent: "center",
                display: "flex",
              }}
            >
              Gallery
            </Typography>

            {isLoading && <p>Loading...</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}
            {!isLoading &&
              !error &&
              Object.entries(groupedItems).map(([type, items]) => (
                <div key={type}>
                  <Typography
                    variant="h6"
                    sx={{ marginTop: 2, marginBottom: 1 }}
                  >
                    {type === "video/streaming"
                      ? "Videos"
                      : type === "application/pdf"
                        ? "PDFs"
                        : type.startsWith("image/")
                          ? "Images"
                          : type}
                  </Typography>
                  <Grid container spacing={2}>
                    {items.map((item: GalleryItem) => (
                      <Grid item xs={4} key={item.staff_lesson_slide_id}>
                        <Box
                          onClick={() => this.handleModalOpen(item)}
                          sx={{
                            cursor: "pointer",
                            textAlign: "center",
                            "&:hover": { opacity: 0.7 },
                          }}
                        >
                          {this.renderThumbnail(item)}
                          <Typography variant="caption" display="block">
                            {item.staff_lesson_slide_name}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
          </Box>
        </Drawer>

        <Modal
          open={modalOpen}
          onClose={this.handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", // Center the modal
              width: "95vw", // Control width
              height: "90vh", // Control height
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",

            }}
          >
            <IconButton
              onClick={this.handleModalClose}
              // sx={{
              //   position: "absolute",
              //   right: 8,
              //   top: 8,
              //   color: (theme: { palette: { grey: any[]; }; }) => theme.palette.grey[500],
              // }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                // color: (theme: { palette: { grey: { [key: number]: string } } }) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {selectedItem && (
              <>
                {/* <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ mb: 2 }}
                >
                  {selectedItem.staff_lesson_slide_name}
                </Typography> */}

                {/* <hr
               style={{
                border: '1px',
                width: '50%',
                margin:'20px auto'
               }}/> */}

                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "auto",
                  }}
                >
                  {this.renderFullContent(selectedItem)}
                </Box>
              </>
            )}
          </Box>
        </Modal>
      </div>
    );
  }
}

export default GalleryComponent;
